<template>
  <div class="basic-search">
    <input :value="internalValue" :placeholder="pladceholderText" @input="onChangeDelay">
    <div v-if="showIcon" class="icon" />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: String,
    delay: {
      type: Number,
      default: 700,
    },
    showIcon: { type: Boolean, default: true },
    pladceholderText: String,
  },
  data() {
    return {
      internalValue: this.value,
    }
  },
  watch: {
    value: {
      handler() {
        this.internalValue = this.value
      },
      immediate: true,
    },
  },
  methods: {
    onChangeDelay(event) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.internalValue = event.target.value
        this.$emit('change', event.target.value)
      }, this.delay)
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-search
  position: relative
  min-width: vw(100px)
  height: vw(46px)

  > input
    padding-right: vw(60px)
    padding-left: 1em
    min-width: vw(100px)
    width: 100%
    height: 100%
    border: solid 1px $dark-gray
    border-radius: 4px
    
    &::placeholder
      color: $dark-gray

    &:focus-visible,
    &:active
      outline-color: $primary-theme-color
    .mobile & 
      m-font-size(15,24)

  .icon
    position: absolute
    top: 50%
    right: vw(15px)
    transform: translateY(-50%)
    m-icon("shape", 36)
</style>